export const ConstructableLogo = ({
  className,
  size = 36,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.9997 23.9993C8.81333 23.9993 5.80588 22.7458 3.52935 20.47C1.2535 18.1941 0 15.186 0 11.9997C0 8.81333 1.1731 6.00821 3.30291 3.76116C5.57005 1.37141 8.73964 0 11.9997 0C12.7346 0 13.3309 0.596265 13.3309 1.33121C13.3309 2.06616 12.7346 2.66242 11.9997 2.66242C9.46654 2.66242 7.00109 3.73101 5.23507 5.5935C3.57558 7.34344 2.66175 9.61862 2.66175 11.9997C2.66175 14.3807 3.63789 16.814 5.41127 18.5874C7.18466 20.3608 9.52416 21.3369 11.999 21.3369C14.4738 21.3369 16.6552 20.4231 18.4052 18.7636C20.2362 17.0271 21.3001 14.6152 21.3356 12.1283C21.3081 11.3659 20.679 10.7535 19.9099 10.7535C19.1408 10.7535 18.4835 11.394 18.4835 12.1799C18.4835 13.9506 17.7881 15.6221 16.5239 16.885C15.2604 18.1486 13.5895 18.8447 11.8188 18.8447C8.14337 18.8447 5.15334 15.8546 5.15334 12.1792C5.15334 10.4239 5.83938 8.76308 7.08349 7.50289C8.35039 6.22058 10.032 5.51378 11.8181 5.51378C12.5531 5.51378 13.1493 6.11004 13.1493 6.84499C13.1493 7.57993 12.5531 8.1762 11.8181 8.1762C10.7488 8.1762 9.73989 8.60162 8.97747 9.37409C8.22779 10.1325 7.81509 11.1287 7.81509 12.1799C7.81509 14.3874 9.61125 16.1829 11.8181 16.1829C12.878 16.1829 13.8802 15.7642 14.642 15.0031C15.4031 14.242 15.8225 13.2391 15.8225 12.1792C15.8225 9.9248 17.6568 8.09044 19.9112 8.09044C22.1656 8.09044 23.9008 9.82698 23.996 11.9983H24L23.9987 12.1792C23.9491 15.3749 22.585 18.4701 20.2382 20.6951C17.9925 22.8256 15.0667 23.9987 12.0003 23.9987L11.9997 23.9993Z"
        fill="#18181B"
      />
    </svg>
  );
};
